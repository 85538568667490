import PropTypes from 'prop-types'

// TODO: make this more explicit
export const GatsbyImageProp = PropTypes.object

export const ChildrenProps = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]).isRequired

export const VideoProps = PropTypes.shape({
  src: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  credit: PropTypes.string.isRequired,
  creditName: PropTypes.string.isRequired,
  creditSlug: PropTypes.string.isRequired,
  category: PropTypes.string,
  year: PropTypes.string,
})

export const ProjectProps = PropTypes.shape({
  category: PropTypes.string,
  client: PropTypes.string.isRequired,
  clips: PropTypes.arrayOf(PropTypes.string),
  credit: PropTypes.string.isRequired,
  creditName: PropTypes.string.isRequired,
  creditSlug: PropTypes.string.isRequired,
  imageSquare: GatsbyImageProp,
  link: PropTypes.string,
  media: PropTypes.string,
  synopsis: PropTypes.string,
  title: PropTypes.string.isRequired,
  video: PropTypes.string,
  year: PropTypes.string,
})

export const NewsItemProps = PropTypes.shape({
  date: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  year: PropTypes.number,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.string),
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      titleBold: PropTypes.string,
      subtitle: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  region: PropTypes.string,
  language: PropTypes.string,
  runtime: PropTypes.string,
  image: GatsbyImageProp,
  link: PropTypes.string,
})

export const DirectorAwardsProps = PropTypes.arrayOf(
  PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        style: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  })
)

export const DirectorProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  nameShort: PropTypes.string.isRequired,
  role: PropTypes.string,
  first: PropTypes.string,
  last: PropTypes.string,
  slug: PropTypes.string.isRequired,
  bio: PropTypes.string,
  clips: PropTypes.arrayOf(PropTypes.string),
  awards: DirectorAwardsProps,
  titles: PropTypes.arrayOf(PropTypes.string),
  titlesDisplay: PropTypes.string,
})

export const TeamProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleLockup: PropTypes.string,
  bio: PropTypes.string,
  image: PropTypes.object,
})

export const CaseStudyProps = PropTypes.shape({
  title: PropTypes.string,
  location: PropTypes.string,
  year: PropTypes.number,
  client: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.string),
  image: GatsbyImageProp,
  synopsis: PropTypes.string,
  link: PropTypes.string,
})

export const CaseStudySectionProps = PropTypes.shape({
  title: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  image: GatsbyImageProp,
  link: PropTypes.string,
})

export const CaseStudyParentProps = PropTypes.shape({
  title: PropTypes.string,
  display: PropTypes.string,
  sections: PropTypes.arrayOf(CaseStudySectionProps),
})
