import React from 'react'
import PropTypes from 'prop-types'

const PalLogo = (props) => {
  const fill = props.color && props.color !== 'white' ? props.color : '#fffaf0'

  return (
    <svg className="pal-logo" viewBox="0 0 1520 786">
      <g>
        <g>
          <path
            fill={fill}
            d="M722.9,446.9c-68,46-166,47-243,47h-191v249h-252v-706h484c24,0,49,0,73,2c83,5,154,25,194,105c11,23,25,59,25,118
            C812.9,295.9,808.9,389.9,722.9,446.9z M550.9,229.9c-17-30-48-33-78-33h-184v138h165c29,0,43,0,57-4c31-8,49-32,49-65
            C559.9,247.9,553.9,235.9,550.9,229.9z"
          />
        </g>
        <g>
          <path fill={fill} d="M868.1,742.9v-706h254v534h361v172H868.1z" />
        </g>
        <g>
          <path
            fill={fill}
            d="M729.4,742.8l-10.6-11.2c-12.9,5.3-32,13.9-64.7,16.5c-6.9,0.7-13.9,1-21.1,1c-5.3,0-20.5-0.7-36-4.3
            c-10.2-2.6-62-16.5-62.4-65.7c0-6.6,0.7-19.1,10.2-32.7c14.9-20.5,40.3-27.4,62.4-34.3c-4-5.3-14.2-16.8-14.2-36
            c0-5.6,1-10.2,1.6-12.2c2-7.6,7.9-22.1,25.7-31.3c11.9-5.9,31-10.9,61.7-10.9c4.6,0,18.8,0,32.7,2.6c41.6,8.6,51.5,32.7,51.5,52.1
            c0,13.2-5,26.1-14.5,35c-14.5,13.2-39.9,19.5-43.2,20.5l28.7,29.4c6.9-7.6,9.6-12.5,13.9-21.8l59.4,8.2c-2,4.6-4.3,9.2-6.6,13.9
            c-10.6,20.1-26.4,33.3-32,38.3l40.3,42.9H729.4z M639.3,646.8c-12.5,4-28.1,10.2-28.1,26.1c0,2.3,0.7,7.9,4,13.2
            c5,7.6,15.8,14.9,35.3,14.9c7.9,0,17.5-1,33.7-5.9L639.3,646.8z M684.8,558c-14.5,0-19.5,8.9-19.5,15.2c0,4.3,2.3,10.2,8.2,17.8
            c2.6,3,4.6,4.6,5.9,5.6c3-0.7,5.6-1.3,8.6-2.3c13.5-5,17.5-11.2,17.5-18.8C705.6,568.6,701,558,684.8,558z"
          />
        </g>
      </g>
    </svg>
  )
}

PalLogo.propTypes = {
  color: PropTypes.string,
}

export default PalLogo
