exports.components = {
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-coming-soon-footer-index-js": () => import("./../../../src/pages/coming-soon/footer/index.js" /* webpackChunkName: "component---src-pages-coming-soon-footer-index-js" */),
  "component---src-pages-coming-soon-index-js": () => import("./../../../src/pages/coming-soon/index.js" /* webpackChunkName: "component---src-pages-coming-soon-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-location-index-js": () => import("./../../../src/pages/contact/location/index.js" /* webpackChunkName: "component---src-pages-contact-location-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-studio-index-js": () => import("./../../../src/pages/studio/index.js" /* webpackChunkName: "component---src-pages-studio-index-js" */),
  "component---src-pages-studio-logo-grid-index-js": () => import("./../../../src/pages/studio/logo-grid/index.js" /* webpackChunkName: "component---src-pages-studio-logo-grid-index-js" */),
  "component---src-pages-studio-team-index-js": () => import("./../../../src/pages/studio/team/index.js" /* webpackChunkName: "component---src-pages-studio-team-index-js" */),
  "component---src-templates-project-page-index-js": () => import("./../../../src/templates/project-page/index.js" /* webpackChunkName: "component---src-templates-project-page-index-js" */)
}

