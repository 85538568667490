const React = require('react')
const Layout = require('./src/layouts/default').default

exports.shouldUpdateScroll = ({
  routerProps: {location},
  getSavedScrollPosition,
}) => {
  // transition duration from `templates/page`
  const TRANSITION_DELAY = 500
  // if it's a "normal" route
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}

exports.wrapPageElement = ({element, props}) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
