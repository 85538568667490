import React, {createContext, useState} from 'react'
import {ChildrenProps} from '../utils/propTypes'

const initialState = {
  first: true,
}

export const PageContext = createContext(initialState)

export const PageProvider = ({children}) => {
  const [isFirst, setIsFirst] = useState(true)

  const value = React.useMemo(
    () => ({
      isFirst,
      setIsFirst,
    }),
    [isFirst]
  )

  return (
    <PageContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

PageProvider.propTypes = {
  children: ChildrenProps,
}
