import React from 'react'
import HeaderComponent from '../components/header'
import {HeaderProvider} from '../context/header-context'
import '../styles/app.scss'
import {AnimatePresence} from 'framer-motion'
import {PageProvider} from '../context/page-context'
import {ChildrenProps} from '../utils/propTypes'

const DefaultLayout = ({children}) => {
  return (
    <PageProvider>
      <HeaderProvider>
        {/* TEMP Placeholder Content */}
        <HeaderComponent />
        <AnimatePresence>{children}</AnimatePresence>
      </HeaderProvider>
    </PageProvider>
  )
}

DefaultLayout.propTypes = {
  children: ChildrenProps,
}

export default DefaultLayout
