import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const Button = (props) => {
  const {className, isStatic, text, color, onMouseEnter, onMouseLeave} = props
  return (
    <div
      className={`button ${className ? className : ''} ${
        isStatic ? 'static' : 'animates'
      }`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div style={{color}} className="">
        <div className="text">{text}</div>
      </div>
    </div>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  isStatic: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

export default Button
